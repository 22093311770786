/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect } from 'react';
import { Alert, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { register } from '../../services/Partner';
import AuthForm from '../../components/AuthForm';
import handleLogin from '../../utils/handle-login';

function Register() {
  const history = useHistory();
  const location = useLocation();

  const isPartner = document?.location?.host === 'partner.buddieshr.com';

  useEffect(() => {
    document.title = isPartner
      ? 'Register as Partner | BuddiesHR'
      : 'Register as Ambassador | BuddiesHR';
  }, []);

  const onFinish = async (values) => {
    try {
      const { _id: sessionId } = await register(values);
      handleLogin(sessionId, history, location);
    } catch (error) {
      message.error('Unknown error. Please contact support@buddieshr.com!');
    }
  };

  return (
    <div className="Register_container">
      <div className="Register_wrapper">
        <div className="Register_left">
          <h1 className="bold">
            {isPartner ? 'Partner rules' : '🏅 Ambassador rules'}
          </h1>
          {isPartner ? (
            <>
              <Alert
                message="Please read carefully, if you have any question, you can reach out at support@buddieshr.com"
                type="info"
              />
              <br />
              <div>
                <ol className="Partner_rules">
                  <li>
                    {
                      'You earn 50% commissions on all payments coming from Slack workspaces that installed our apps through one of your links. '
                    }
                  </li>
                  <li>
                    The minimum amount necessary for the payout is $50. PayPal
                    transfer if you are a professional (invoice required from
                    you), Amazon gift card otherwise.
                  </li>
                  <li>
                    This is not for a personal use. Every personal account used
                    will end up by a removal of the account.
                  </li>
                </ol>
              </div>
            </>
          ) : (
            <div>
              <Alert
                showIcon
                style={{ border: 'none' }}
                description={
                  <p style={{ marginBottom: 0 }}>
                    Full details on the{' '}
                    <a href="https://buddieshr.com/ambassador-program">
                      Ambassador Program page
                    </a>
                  </p>
                }
                type="info"
              />
              <ul
                style={{
                  marginTop: 24,
                  listStyle: 'none',
                  paddingInlineStart: 12,
                  fontSize: 18,
                }}
              >
                <li>✅&nbsp;&nbsp;50% commissions</li>
                <li>✅&nbsp;&nbsp;No expiry date</li>
                <li>✅&nbsp;&nbsp;Low user churn rate</li>
                <li>✅&nbsp;&nbsp;No support or implementation needed</li>
              </ul>
            </div>
          )}
        </div>
        <div className="Register_right">
          <AuthForm
            cta="Register"
            title={
              isPartner ? 'Register as partner' : 'Register as an Ambassador'
            }
            onFinish={onFinish}
            inputs={['email', 'password', 'passwordConfirmation']}
            links={[{ url: '#/auth/login', text: 'Already have an account?' }]}
          />
        </div>
      </div>
    </div>
  );
}

export default Register;
