/* eslint-disable comma-dangle */
import React from 'react';
import { message } from 'antd';
import { forgetPassword } from '../../services/Partner';
import AuthForm from '../../components/AuthForm';

function ForgetPassword() {
  const onFinish = async (values) => {
    try {
      await forgetPassword(values);
      message.success(
        `An email to reset your password has been sent to ${values.email}`
      );
      setTimeout(() => {
        window.location.href = '/#/login';
      }, 1000);
    } catch (error) {
      message.error('Unknown error. Please contact support@buddieshr.com!');
    }
  };

  return (
    <div className="Login_wrapper">
      <div className="Login_inner">
        <AuthForm
          title="Forgot Password?"
          cta="Reset my password"
          onFinish={onFinish}
          inputs={['email']}
          links={[{ url: '#/auth/login', text: 'Log in' }]}
        />
      </div>
    </div>
  );
}

export default ForgetPassword;
