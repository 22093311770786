import axios from 'axios';

export default class Api {
  static call(method, url, body = {}) {
    return axios({
      method,
      url: `${process.env.API_URL}${url}`,
      data: body,
      headers: {
        sessionid: localStorage.getItem('sessionId'),
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'x-is-partner': `${document?.location?.host === 'partner.buddieshr.com'}`,
      },
    });
  }

  static get(url, body) {
    return this.call('GET', url, body);
  }

  static post(url, body) {
    return this.call('POST', url, body);
  }

  static put(url, body) {
    return this.call('PUT', url, body);
  }

  static delete(url, body) {
    return this.call('DELETE', url, body);
  }
}
