/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { DashboardOutlined, LogoutOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Menu, Button } from 'antd';
import { logout } from '../../services/Partner';
import './index.less';

function AppBar() {
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [hasClosedTipLinkedin, setHasClosedTipLinkedin] = useState(false);

  const onClick = () => {
    logout();
    localStorage.removeItem('sessionId');
    queryClient.clear();
    history.push('/auth');
  };
  const isPartner = document?.location?.host === 'partner.buddieshr.com';

  const closeBanner = () => {
    localStorage.setItem('closeTipLinkedin', true);
    setHasClosedTipLinkedin(true);
  };

  useEffect(() => {
    const valueFromStorage = localStorage.getItem('closeTipLinkedin');
    setHasClosedTipLinkedin(valueFromStorage === 'true');
  });
  return (
    <>
      <div id="AppBar">
        <div className="Header_inner">
          <div className="AppBar_logo">
            <img src="/logo.png" alt="buddies logo" />
          </div>
          <Menu
            style={{ minWidth: 220 }}
            mode="horizontal"
            selectedKeys={[location.pathname.split('/')[1]]}
          >
            <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
              <a href="#/dashboard">{`${
                isPartner ? 'Partner' : 'Ambassador'
              } Dashboard`}</a>
            </Menu.Item>
          </Menu>
          <div className="Fill" />
          <Button icon={<LogoutOutlined />} onClick={onClick}>
            Log out
          </Button>
        </div>
      </div>
      {!isPartner && !hasClosedTipLinkedin && (
        <div className="Banner">
          <div className="Banner_inner">
            <img
              alt="linkedin role"
              src="linkedin.svg"
              style={{ width: 20, height: 20, marginRight: 10 }}
            />
            You can now add ambassador for Buddieshr to your linkedin
            experiences.
            <a
              style={{ marginLeft: 12, fontWeight: 'bold' }}
              onClick={closeBanner}
            >
              Close Tip
            </a>
          </div>
        </div>
      )}
    </>
  );
}

export default AppBar;
